import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TuneIcon from '@mui/icons-material/Tune';
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import { useSession } from './SessionContext';
import Brand  from '../Assets/brandLogo.png';

export default function AccountSidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const { sId, setSId} = useSession();


  useEffect(() => {
    var sId = document.cookie.replace(/(?:(?:^|.*;\s*)sId\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (sId) {
      setSId(sId);
    }
  }, [setSId]);

  const collapseSidebar = () => {
    console.log('collapseSidebar called')
    setCollapsed(!collapsed);
  };

  return (
    <>
<div className='d-none d-lg-flex' style={{ display: "flex", minHeight: "100vh"}}>
        <Sidebar className="app" collapsed={collapsed}>
          <Menu >
            <MenuItem className="menu1 sidebar-item my-2" icon={<MenuRoundedIcon className='icons' onClick={collapseSidebar} />}>
            <h4 className='mt-3'><strong><img src={Brand} alt='MORD Al-Arabia For Trading Ltd.' className="responsive" width="600" height="400" /></strong></h4>
            </MenuItem>
            <MenuItem className="sidebar-item" component={<Link to="/AccountDashboard" className="link" />} icon={<GridViewRoundedIcon className="icons" />}> <span className="text">Dashboard</span> </MenuItem>
            <MenuItem className="sidebar-item" component={<Link to="/AccountViewPurchase" className="link" />} icon={<LocalMallIcon className="icons" />}> <span className="text">View Purchase</span> </MenuItem>
            <SubMenu className="sidebar-item"  label={<span className="text">Setting</span>} icon={<TuneIcon className="icons"  />}>
            <MenuItem className="text-item" component={<Link to="/LoginMaster" className="link" />} icon={<AccountCircleIcon className="text-icons" />}> <span className="text-text">Create User Login</span> </MenuItem>
            </SubMenu>
            <MenuItem className="sidebar-item"  icon={<SettingsApplicationsRoundedIcon className="icons"  />}> <span className="text">Session-{sId}</span> </MenuItem>
          </Menu>
        </Sidebar>
    </div>   
    </>
  )
}

