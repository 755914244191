import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

export default function BranchMaster() {
    const { puchaseType, setPuchaseType} = useSession();
    const { BID } = useParams();
    const [data, setData] = useState([]);
    const [branchName, setBranchName] = useState('');
    const [shop, setShop] = useState('');
    const [shopId, setShopId] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [DeleteId, setDeleteId] = useState(''); 
    const [isDeleted, setIsDeleted] = useState(false); 
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/PurchaseDashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)puchaseType\s*=\s*([^;]*).*$)|^.*$/, "$1");

      if (loginType) {
        setPuchaseType(loginType);
      }
    }, [setPuchaseType]);

    const handleBranchNameChange = (e) => {
        setBranchName(e.target.value);
    }

    const handleShopChange = (e) => {
        const selectedValue = e.target.value
        setShop(selectedValue)
        const selectedShop = shopOption.find(option => option.name === selectedValue);
        if(selectedShop){
          // console.log('Selected selectedClasses ID:', selectedClasses.id);
          setShopId(selectedShop.id)
        }
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    const handleReset = () => {
        setShop('');
        setShopId([]);
        setBranchName('');
        navigate(`/BranchMaster`)
    };

    useEffect(() => {
      fetchBranch();
      fetchShops();
    }, []);

    const fetchShops = async () => {
        try {
          const response = await fetch(`/api/getShop`); 
          if (response.ok) {
            const data = await response.json();
          //   console.log(data)
            const filteredOptions = data.recordset.filter(option => option.ShopName !== null).map(option=> ({
                id: option.SID, 
                name: option.ShopName,
            }));
            setShopOption(filteredOptions);
            // console.log(filteredOptions) 
          } else {
            console.error('Error fetching options');
          }
        } catch (error) {
            console.error('Error fetching options:', error);
          }
    };

    const fetchBranch = async () => {
      try {
        const response = await fetch(`/api/getBranch`);
        const data = await response.json();
    
        const dataArray = data.recordset;
        if (Array.isArray(dataArray)) {
          setData(dataArray);
        } else {
          console.error('Invalid data format from the API:', data);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    useEffect(() => {
      if (BID) {
        fetch(`/api/getBranchs/${BID}`)
          .then((response) => response.json())
          .then((result) => {
            if (result && result.length > 0) {
              const clientdata = result[0];
              setShop(clientdata.ShopName || '');
              setShopId(clientdata.SID || []);
              setBranchName(clientdata.BranchName || '');
            } else {
              console.log('No data received');
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } else {
        // console.log('BID is not defined or falsy');
        setShop('');
        setShopId([]);
        setBranchName('');
      }
    }, [BID]);

    const handleSubmit = async (e) => {
      console.log('handlenoticeupload called');
      if (branchName.trim() === '') {
        alert('Oops! The Branch Name Field Is Empty');
        return;
      }
      e.preventDefault();
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 1000); 
    
        if(BID){
          try {
            const response = await fetch(`/api/UpdateBranch/${BID}`, {
              method: 'PUT', 
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ branchName, shopId, userLoginType: puchaseType }),
            });
      
            if (response.ok) {
              setSuccessMessage('Branch Updated successfully.');
              setShop('');
              setShopId([]);
              setBranchName('');
              fetchBranch();
              navigate(`/BranchMaster`)
            } else {
              console.error('Error updating Branch');
            }
          } catch (error) {
            console.error('Error updating Branch:', error);
          }
        } else {
          try {
            const response = await fetch(`/api/CreateBranch`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ branchName, shopId, userLoginType: puchaseType }),
            });
    
            if (response.ok) {
              setSuccessMessage('Branch Created successfully.');
              setShop('');
              setShopId([]);
              setBranchName('');
              fetchBranch();
            } else {
              console.error('Error creating Branch');
            }
          } catch (error) {
            console.error('Error creating Branch:', error);
          }
        } 
      setIsSubmitting(false);
    };

    const handleEditClick = (BID) => {
      navigate(`/BranchMaster/${BID}`)
    };

    const handleConfirmation = async (BID) => {
      setIsDeleted(true);
      setDeleteId(BID)
    };

    const handleCancel = async () => {
      setIsDeleted(false);
      setDeleteId('')
    };

    const handleDelete = async (BID) => {
      try {
        const response = await fetch(`/api/DeleteBranch/${BID}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          setData((prevData) => prevData.filter(item => item.BID !== BID));
          setSuccessMessage('Branch Deleted successfully.');
          setShowModal(true);
          setTimeout(() => {
            removeSuccessMessage();
          }, 2000);
          setIsDeleted(false);
          DeleteId('');
        } else {
          console.error('Error deleting shop');
        }
      } catch (error) {
        console.error('Error deleting Shop:', error);
      }
    };

    useEffect(() => {
      const currentRoute = location.pathname;
      const newBreadcrumbs = generateBreadcrumbs(currentRoute);
      setBreadcrumbs(newBreadcrumbs);
    }, [location]);
  
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/PurchaseDashboard' }];
  
      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part) => {
        urlSoFar += `/${part}`;
        breadcrumbsArray.push({ label: part, url: urlSoFar });
      });
  
      return breadcrumbsArray;
    };
     
  return (
    <>
{showModal && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Success Message</h5>
          </div>
          <div className="modal-body">
            {successMessage && <p className='text-success'>{successMessage}</p>}
          </div>
        </div>
      </div>
  </div>
<div className='my-2'>
  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
  <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
    <h5 className='text-center'>{BID ? 'Update Branch' : 'Create Branch'}</h5>
    <form>
        <div className='row'>
            <div className='col-md-4'>
                <div>
                    <select className="form-control" id="dropdown" value={shop} onChange={handleShopChange}>
                    <option value="">Select Shop/Market</option>
                    {shopOption.map((option, index) => (
                        <option key={index} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                    </select>      
                </div>
            </div>
            <div className='col-md-4'>
                <div  className='margin-top'>
                    <input  className='form-input' type="text" placeholder='Enter Branch Name' value={branchName}  onChange={handleBranchNameChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <button  className='btn btn-info px-5 btn-width' onClick={handleSubmit}>{BID ? 'Update' : 'Save'}</button>
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <button className='btn btn-warning btn-width' onClick={handleReset}>Reset</button>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div className='d-felx mx-2'>
      <div className='shadow mb-5 rounded scrollOnXaxis branchScroll'>
        <table className='table table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Shop/Market Name</th>
              <th>Branch Name</th>
              <th>Save By</th>
              <th>Date</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td>{item.ShopName}</td>
                <td>{item.BranchName}</td>
                <td>{item.SaveBy}</td>
                <td>{item.SDate.slice(0, 10)}</td>
                <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.BID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg></p></td>
                <td><p className='badge badge-danger-lighten' onClick={() => handleConfirmation(item.BID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/></svg></p></td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
    {isDeleted && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Delete <strong>Branch?</strong></h5>
          </div>
          <div className="modal-body">
            <p>This Will Delete Branch <span className='text-danger'>Permannently</span></p>
            <div className='d-flex flex-row-reverse '>
            <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
            <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
    </>
  )
}
