import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"

const MyLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showCaraousel, setShowCaraousel] = useState(false);
    const navigate = useNavigate();

    const toggleIcon = () => {
        setShowCaraousel(!showCaraousel);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch(`/api/MyLogIn`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
          });
          if (response.ok) {
            const data = await response.json();    
            document.cookie = "sId=" + data.sId;
            document.cookie = "sYear=" + data.sYear;
            localStorage.setItem("isLoggedIn", "true"); 
            if (data.loginType === 'Admin') {
              navigate('/Dashboard');
            } else if (data.loginType === 'Purchase') {
              document.cookie = "PurchaseLoginId=" + data.PurchaseLoginId;
              document.cookie = "purchaserName=" + data.purchaserName;
              document.cookie = "puchaseType=" + data.puchaseType;
              localStorage.setItem("isPurchaseLoggedIn", "true"); 
              navigate('/PurchaseDashboard');
            } else if (data.loginType === 'Account') {
              document.cookie = "accountLoginId=" + data.accountLoginId;
              document.cookie = "accountantName=" + data.accountantName;
              document.cookie = "accountType=" + data.accountType;
              localStorage.setItem("isAccountLoggedIn", "true"); 
              navigate('/AccountDashboard');
            }
            window.location.reload();
          } else {
            setErrorMessage('Username or password is incorrect'); 
          }
        } catch (error) {
          console.error('Error during login:', error);
        }
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
      

    return (
        <>
            <div className="d-flex justify-content-center align-items-center vh-100 mx-5">
                <div className="modal-content">
                    <div className="modal-body shadow-lg p-1 rounded">
                            {showCaraousel ? (
                                <div className="row mx-1 linear-bg">
                                    <div className="text-center col-md-5">
                                        <h6 className="my-4 text-white display-none">Welcome To <span>MORD Al-Arabia For Trading LTD</span></h6>
                                        <div className='d-flex justify-content-center'>
                                        <div className="login-icon mb-2 margin-top">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753"/>
                                            </svg>
                                        </div>
                                        </div>
                                        <h3 className="my-4 text-white mH3">RAYAN INVENTORY MANAGEMENT SOFTWARE</h3>
                                        <p className="text-white  display-none">Streamline your inventory control with our software, offering real-time tracking and organization of your stock. Simplify stock management, streamline orders, and optimize inventory levels for efficient business operations.</p>
                                    </div>
                                    <div className='col-md-7 bg-image'>
                                        <div className='py-3 margin-left'>
                                          <div className='margin-left'>
                                            <h4>Login To your Account</h4>
                                            <p>Welcome Back!</p>
                                          </div>
                                        <form className=' margin-left'>
                                            <div className='form-group'>
                                                <label htmlFor="username">Username:</label>
                                                <input type="text" className='form-control login-input' id="username" name="username" autoComplete="current-username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                                <div className='d-flex justify-content-center align-items-center LogInicon'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16"><path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/><path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/></svg>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor="password">Password:</label>
                                                <input type={showPassword ? 'text' : 'password'} className='form-control login-input' id="password" name="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                                <div className='d-flex justify-content-center align-items-center LogInicon' onClick={togglePasswordVisibility}> 
                                                {showPassword ? (
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16"><path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/><path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/></svg>
                                                ) : (
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16"><path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/></svg>
                                                )}
                                                </div>              
                                            </div>
                                            <div className='row'>
                                            <div className='col-md-6 custom-md-6 d-flex'>
                                                <input type='checkbox' className=" mx-2 mb-3 mobileCheckbox" /><p className="sm-p mobileP">Rememeber me</p>
                                            </div>
                                            <div className='col-md-6 custom-md-6'>
                                              <p style={{textAlign: 'right', color:'blue'}}><span className="link-hover mobileP">Forget Password?</span></p>
                                            </div>
                                            </div>
                                            <button type="submit" className='btn btn-lg btn-login py-1 mt-0 ' onClick={handleSubmit}>Login</button>
                                            {errorMessage && <span className="text-danger mb-0 mx-2">{errorMessage}</span>}
                                        </form>                                     
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center">
                                <div className="shadow p-5 linear-bg rounded">
                                    <h5 className="mb-4 text-white">Welcome To <span>MORD Al-Arabia For Trading LTD</span></h5>
                                    <div className='d-flex justify-content-center'>
                                        <div className="login-icon mb-3" onClick={toggleIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className="mb-4 text-white">RAYAN INVENTORY MANAGEMENT SOFTWARE</h3>
                                    <p className="text-white">Streamline your inventory control with our software, offering real-time tracking and organization of your stock. Simplify stock management, streamline orders, and optimize inventory levels for efficient business operations.</p>
                                </div>
                                </div>          
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyLogin;
