import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSession } from './SessionContext';
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TuneIcon from '@mui/icons-material/Tune';
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Brand  from '../Assets/mBrandLogo.png';

export default function PurchaseNavbar() {
  const {puchaseType, setPuchaseType, sId, setSId, purchaserName, setPurchaserName} = useSession();
  const [switchTheme, setSwitchTheme] = useState('light-mode');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [icon, setIcon] = useState(isDarkMode);

  useEffect(() => {
    var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)puchaseType\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var sId = document.cookie.replace(/(?:(?:^|.*;\s*)sId\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var loginName = document.cookie.replace(/(?:(?:^|.*;\s*)purchaserName\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (loginType) {
      setPuchaseType(loginType);
      setSId(sId);
      setPurchaserName(loginName);
    }
  }, [setPuchaseType, setSId, setPurchaserName]);

  const toggleMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    setIcon(isDarkMode)
    setSwitchTheme('light-mode')

    fetch(`/api/SwitchTheme`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ icon }),
    })
  };

  const handleLogout = () => {  
  fetch('/Logout', {
    method: 'POST',
  })
    .then((response) => {
      if (response.status === 200) {
        window.location.href = "/";
        window.localStorage.removeItem("isLoggedIn");
        window.localStorage.removeItem("isPurchaseLoggedIn");
      }
    })
    .catch((error) => {
      console.error('Logout failed:', error);
    });
  };

  const closeNavbar = () => {
    // Close the navbar using JavaScript
    const navbarToggler = document.querySelector('.navbar-toggler');
    if (navbarToggler) {
      navbarToggler.click();
    }
  };

 return (
    <>
    <nav className={switchTheme === 'light-mode' ? 'navbar navbar-light bg-white shadow-sm mb-1' : 'navbar theme-Dark shadow-sm mb-1'}>
      <div className="container-fluid">
      <form className="d-flex mt-0" role="search">
      <h1 className="navbar-brand brand d-block d-lg-none" ><strong><img src={Brand} alt='MORD Al-Arabia For Trading Ltd.' className="responsive" width="600" height="400" /></strong></h1>
          <input className="form-control mx-2 d-none d-lg-block" type="search" placeholder="Search" aria-label="Search"/>
          <button className="btn btn-primary d-none d-lg-block" type="submit">Search</button>
      </form>
      <div className="dropdown mx-3 d-none d-lg-flex">
      <span className='my-2 mx-3' onClick={toggleMode}>
      {switchTheme === 'light-mode' ? (
         <p className='badge theme-warning-lighten'>
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-sun" viewBox="0 0 16 16"><path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
       </svg></p>
        ) : (
          <p className='badge theme-night-lighten'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-moon" viewBox="0 0 16 16">
            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278M4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"/>
          </svg></p>
        )}
        </span>
      <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
        {puchaseType &&<strong className={`${switchTheme === 'light-mode' ? 'text-dark' : 'darkThemefColor'}`}>{puchaseType}</strong>}
      </p>
      <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
        <li><Link className="dropdown-item" to="/" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <AccountCircleIcon style={{ marginRight: '8px' }} />Profile</Link></li>
        <li><hr className="dropdown-divider"  /></li>
        <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
      </ul>
    </div>  
    <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><strong><img src={Brand} alt='MORD Al-Arabia For Trading Ltd.' className="responsive" width="600" height="400" /></strong></h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li className="nav-item" onClick={closeNavbar}>
            <Link className="nav-link" to="/PurchaseDashboard">
             <GridViewRoundedIcon style={{ marginRight: '8px' }} />Dashboard</Link>
          </li>
          <li className="nav-item" onClick={closeNavbar}>
            <Link className="nav-link" to="/PurchaseMaster?pType=Cash">
             <LocalMallIcon style={{ marginRight: '8px' }} />Purchase Cash</Link>
          </li>
          <li className="nav-item" onClick={closeNavbar}>
            <Link className="nav-link" to="/PurchaseMaster?pType=Card">
             <AddCardIcon style={{ marginRight: '8px' }} />Purchase Card</Link>
          </li>
          <li className="nav-item" onClick={closeNavbar}>
            <Link className="nav-link" to="/PurchaseFinal">
             <CheckCircleIcon style={{ marginRight: '8px' }} />View Final Submit</Link>
          </li>
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <NotInterestedIcon style={{ marginRight: '8px' }}/>
              Reject Invoices
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/RejectMster?pType=Cash"><AddLocationIcon style={{ marginRight: '8px' }}/>View Reject Cash</Link></li>
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/RejectMster?pType=Card"><LocalMallIcon style={{ marginRight: '8px' }}/>View Reject Card</Link></li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <TuneIcon style={{ marginRight: '8px' }}/>
              Setting
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/CityMaster"><AddLocationIcon style={{ marginRight: '8px' }}/>City Master</Link></li>
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/ShopMaster"><LocalMallIcon style={{ marginRight: '8px' }}/>Shop Master</Link></li>
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/BranchMaster"><LocalMallIcon style={{ marginRight: '8px' }}/>Branch Master</Link></li>
            </ul>
          </li>
        </ul>
          <div>
          <hr />
          <div className="dropdown nav-item mt-3">
           <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
             <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
             {puchaseType &&<strong className='text-dark'>{puchaseType}</strong>}
           </p>
           <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
              {purchaserName &&<li><Link className="dropdown-item" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
             <AccountCircleIcon style={{ marginRight: '8px' }} />{purchaserName}</Link></li>}
              {sId &&<li><Link className="dropdown-item" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
             <CircleNotificationsIcon style={{ marginRight: '8px' }} />Session-{sId}</Link></li>}
             <li><hr className="dropdown-divider"  /></li>
             <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
             <LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
           </ul>
          </div> 
          </div>
      </div>
    </div>
  </div>
      </nav>
    </>
  )
}
