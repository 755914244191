import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

export default function CityMaster() {
    const { puchaseType, setPuchaseType} = useSession();
    const { CID } = useParams();
    const [data, setData] = useState([]);
    const [cityName, setCityName] = useState('');
    const [DeleteId, setDeleteId] = useState(''); 
    const [isDeleted, setIsDeleted] = useState(false); 
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/PurchaseDashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)puchaseType\s*=\s*([^;]*).*$)|^.*$/, "$1");

      if (loginType) {
        setPuchaseType(loginType);
      }
    }, [setPuchaseType]);

    const handleCityNameChange = (e) => {
        setCityName(e.target.value);
    }

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    const handleReset = () => {
        setCityName('');
        navigate(`/CityMaster`)
    };

    useEffect(() => {
      fetchClass();
    }, []);

    const fetchClass = async () => {
      try {
        const response = await fetch(`/api/getCity`);
        const data = await response.json();
    
        const dataArray = data.recordset;
        if (Array.isArray(dataArray)) {
          setData(dataArray);
        } else {
          console.error('Invalid data format from the API:', data);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    useEffect(() => {
      if (CID) {
        fetch(`/api/getCity/${CID}`)
          .then((response) => response.json())
          .then((result) => {
            if (result && result.length > 0) {
              const clientdata = result[0];
              setCityName(clientdata.CityName || '');
            } else {
              console.log('No data received');
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } else {
        console.log('CID is not defined or falsy');
        setCityName('');
      }
    }, [CID]);

    const handleSubmit = async (e) => {
      console.log('handlenoticeupload called');
      if (cityName.trim() === '') {
        alert('Oops! The City Name Field Is Empty');
        return;
      }
      e.preventDefault();
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 1000); 
    
        if(CID){
          try {
            const response = await fetch(`/api/UpdateCity/${CID}`, {
              method: 'PUT', 
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ cityName, userLoginType: puchaseType }),
            });
      
            if (response.ok) {
              setSuccessMessage('City Updated successfully.');
              setCityName('');
              fetchClass();
              navigate(`/CityMaster`)
            } else {
              console.error('Error updating City');
            }
          } catch (error) {
            console.error('Error updating City:', error);
          }
        } else {
          try {
            const response = await fetch(`/api/CreateCity`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ cityName, userLoginType: puchaseType }),
            });
    
            if (response.ok) {
              setSuccessMessage('City Created successfully.');
              setCityName('');
              fetchClass();
            } else {
              console.error('Error creating City');
            }
          } catch (error) {
            console.error('Error creating City:', error);
          }
        } 
      setIsSubmitting(false);
    };

    const handleEditClick = (CID) => {
      navigate(`/CityMaster/${CID}`)
    };

    const handleConfirmation = async (CID) => {
      setIsDeleted(true);
      setDeleteId(CID)
    };

    const handleCancel = async () => {
      setIsDeleted(false);
      setDeleteId('')
    };

    const handleDelete = async (CID) => {
      try {
        const response = await fetch(`/api/DeleteCity/${CID}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          setData((prevData) => prevData.filter(item => item.CID !== CID));
          setSuccessMessage('City Deleted successfully.');
          setShowModal(true);
          setTimeout(() => {
            removeSuccessMessage();
          }, 2000);
          setIsDeleted(false);
          DeleteId('');
        } else {
          console.error('Error deleting City');
        }
      } catch (error) {
        console.error('Error deleting City:', error);
      }
    };

    useEffect(() => {
      const currentRoute = location.pathname;
      const newBreadcrumbs = generateBreadcrumbs(currentRoute);
      setBreadcrumbs(newBreadcrumbs);
    }, [location]);
  
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/PurchaseDashboard' }];
  
      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part) => {
        urlSoFar += `/${part}`;
        breadcrumbsArray.push({ label: part, url: urlSoFar });
      });
  
      return breadcrumbsArray;
    };
     
  return (
    <>
{showModal && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Success Message</h5>
          </div>
          <div className="modal-body">
            {successMessage && <p className='text-success'>{successMessage}</p>}
          </div>
        </div>
      </div>
  </div>
<div className='my-2'>
  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
  <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
    <h5 className='text-center'>{CID ? 'Update City' : 'Create City'}</h5>
    <form>
        <div className='row'>
            <div className='col-md-8'>
                <div>
                    <input  className='form-input' type="text" placeholder='Enter City Name' value={cityName}  onChange={handleCityNameChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <button  className='btn btn-info btn-width' onClick={handleSubmit}>{CID ? 'Update' : 'Save'}</button>
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
              <div className='margin-top'>
                <button className='btn btn-warning btn-width' onClick={handleReset}>Reset</button>
              </div>
            </div>
        </div>
    </form>
    </div>
    <div className='d-felx mx-2'>
      <div className='shadow mb-5 rounded'>
        <table className='table table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Date</th>
              <th>City Name</th>
              <th>Save By</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td>{item.SDate.slice(0, 10)}</td>
                <td>{item.CityName}</td>
                <td>{item.SaveBy}</td>
                <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.CID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg></p></td>
                <td><p className='badge badge-danger-lighten' onClick={() => handleConfirmation(item.CID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/></svg></p></td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
    {isDeleted && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Delete <strong>City?</strong></h5>
          </div>
          <div className="modal-body">
            <p>This Will Delete City <span className='text-danger'>Permannently</span></p>
            <div className='d-flex flex-row-reverse '>
            <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
            <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
    </>
  )
}

