import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

export default function LoginMaster() {
    const { LoginID } = useParams();
    const [data, setData] = useState([]);
    const [empName, setEmpName] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardNo, setCardNo] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState([]);
    const typeOption = ['Purchase', 'Account'];
    const [DeleteId, setDeleteId] = useState(''); 
    const [isDeleted, setIsDeleted] = useState(false); 
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/AccountDashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleTypeChange = (e) => {
        const selectedValue = e.target.value
        setType(selectedValue)
    };

    const handleEmpNameChange = (e) => {
        setEmpName(e.target.value)
    };

    const handleCardNameChange = (e) => {
        setCardName(e.target.value)
    };

    const handleCardNoChange = (e) => {
        setCardNo(e.target.value)
    };

    const handleUsernameChange = async (e) => {
        setUsername(e.target.value)
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    const handleReset = () => {
      setType([]);
      setEmpName('');
      setCardName('');
      setCardNo('');
      setUsername('');
      setPassword('');
      navigate(`/LoginMaster`)
    };

    useEffect(() => {
      fetchUsers();
    }, []);

    const fetchUsers = async () => {
      try {
        const response = await fetch(`/api/getUsers`);
        const data = await response.json();
    
        const dataArray = data.recordset;
        if (Array.isArray(dataArray)) {
          setData(dataArray);
        } else {
          console.error('Invalid data format from the API:', data);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    useEffect(() => {
      if (LoginID) {
        fetch(`/api/getUsers/${LoginID}`)
          .then((response) => response.json())
          .then((result) => {
            if (result && result.length > 0) {
              const clientdata = result[0];
              setType(clientdata.Type || []);
              setEmpName(clientdata.EmployeeName || '');
              setCardName(clientdata.CardName || '');
              setCardNo(clientdata.CardNo || '');
              setUsername(clientdata.LoginName || '');
              setPassword(clientdata.Password || '');
            } else {
              console.log('No data received');
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } else {
        console.log('PID is not defined or falsy');
        setType([]);
        setEmpName('');
        setCardName('');
        setCardNo('');
        setUsername('');
        setPassword('');
      }
    }, [LoginID]);

    const handleSubmit = async (e) => {
      console.log('handlenoticeupload called');
      if (!type) {
        alert('Oops! The Type Field Is Empty');
        return;
      } else if (username === '') {
        alert('Oops! The User Name Field Is Empty');
        return;
      } else if (password === '') {
        alert('Oops! The Password Field Is Empty');
        return;
      }

      e.preventDefault();
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 1000); 

      if(LoginID){
        try {
          const response = await fetch(`/api/UpdateUsers/${LoginID}`, {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ type, empName, cardName, cardNo, username,  password }),
          });  
            if (response.ok) {
              setSuccessMessage('User Updated successfully.');
              setType([]);
              setEmpName('');
              setCardName('');
              setCardNo('');
              setUsername('');
              setPassword('');
              fetchUsers();
              navigate(`/LoginMaster`)
            } else {
              console.error('Error updating User');
            }
          } catch (error) {
            console.error('Error updating User:', error);
          }
        } else {
          try {
            const response = await fetch(`/api/CreateUser`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type, empName, cardName, cardNo, username,  password }),
            });
    
            if (response.ok) {
              setSuccessMessage('User Created successfully.');
              setType([]);
              setEmpName('');
              setCardName('');
              setCardNo('');
              setUsername('');
              setPassword('');
              fetchUsers();
            } else {
              console.error('Error creating user');
            }
          } catch (error) {
            console.error('Error creating user:', error);
          }
        } 
      setIsSubmitting(false);
    };

    const handleEditClick = (LoginID) => {
      navigate(`/LoginMaster/${LoginID}`)
    };

    const handleConfirmation = async (LoginID) => {
      setIsDeleted(true);
      setDeleteId(LoginID)
    };

    const handleCancel = async () => {
      setIsDeleted(false);
      setDeleteId('')
    };

    const handleDelete = async (LoginID) => {
      try {
        const response = await fetch(`/api/DeleteUser/${LoginID}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          setData((prevData) => prevData.filter(item => item.LoginID !== LoginID));
          setSuccessMessage('User Deleted successfully.');
          setShowModal(true);
          setTimeout(() => {
            removeSuccessMessage();
          }, 2000);
          setIsDeleted(false);
          DeleteId('');
        } else {
          console.error('Error deleting User');
        }
      } catch (error) {
        console.error('Error deleting User:', error);
      }
    };

    useEffect(() => {
      const currentRoute = location.pathname;
      const newBreadcrumbs = generateBreadcrumbs(currentRoute);
      setBreadcrumbs(newBreadcrumbs);
    }, [location]);
  
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/AccountDashboard' }];
  
      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part) => {
        urlSoFar += `/${part}`;
        breadcrumbsArray.push({ label: part, url: urlSoFar });
      });
  
      return breadcrumbsArray;
    };
     
  return (
    <>
{showModal && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Success Message</h5>
          </div>
          <div className="modal-body">
            {successMessage && <p className='text-success'>{successMessage}</p>}
          </div>
        </div>
      </div>
  </div>
<div className='my-2'>
  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
  <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
  <h5 className='text-center'>{LoginID ? `Update User Login` : `Create User Login`}</h5>
    <form>
        <div className='row'>
            <div className='col-md-2 custom-md-6'>
                <div>
                    <select className="form-control" id="dropdown" value={type} onChange={handleTypeChange}>
                    <option value="">Select User Type</option>
                    {typeOption.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                    </select>                
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div >
                    <input  className='form-input' type="text" placeholder='Enter Employee Name' value={empName}  onChange={handleEmpNameChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input' type="text" placeholder='Enter Card Name' value={cardName}  onChange={handleCardNameChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input' type="text" placeholder='Enter Card Number' value={cardNo}  onChange={handleCardNoChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input' type="text" placeholder='Create Username' value={username}  onChange={handleUsernameChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input' type="text" placeholder='Create Password' value={password}  onChange={handlePasswordChange} required />
                </div>
            </div>
            <div className='col-md-6 custom-md-6'>
                <div className='mt-3'>
                    <button  className='btn btn-info btn-width' onClick={handleSubmit}>{LoginID ? 'Update' : 'Save'}</button>
                </div>
            </div>
            <div className='col-md-6 custom-md-6'>
                <div className='mt-3'>
                    <button className='btn btn-warning btn-width' onClick={handleReset}>Reset</button>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div className='d-felx mx-2'>
      <div className='shadow mb-5 rounded scrollOnXaxis branchScroll'>
        <table className='table table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Delete</th>
              <th>Edit</th>
              <th>Username</th>
              <th>Card No</th>
              <th>Card Name</th>
              <th>Name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td><p className='badge badge-danger-lighten' onClick={() => handleConfirmation(item.LoginID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/></svg></p></td>
                <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.LoginID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg></p></td>
                <td>{item.LoginName}</td>
                <td>{item.CardNo}</td>
                <td>{item.CardName}</td>
                <td>{item.EmployeeName}</td>
                <td>{item.Type}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
    {isDeleted && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Delete <strong>User?</strong></h5>
          </div>
          <div className="modal-body">
            <p>This Will Delete User <span className='text-danger'>Permannently</span></p>
            <div className='d-flex flex-row-reverse '>
            <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
            <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
    </>
  )
}
