import React, { useEffect, useState } from 'react';
import { useSession } from './SessionContext';
import ReactApexChart from 'react-apexcharts';
import { Link, useLocation } from "react-router-dom";

const Dashboard = () => {
  const [tableData, setTableData] = useState([]);
  const [serialNumber] = useState(1);
  const [totalCashColl, setTotalCashColl] = useState('');
  const [totalCardColl, setTotalCardColl] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
  // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  // const currentDate = new Date();
  // const currentMonthName = months[currentDate.getMonth()];
  const location = useLocation();
  const { sId, setSId} = useSession();

  useEffect(() => {
    var SSID = document.cookie.replace(/(?:(?:^|.*;\s*)sId\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (SSID) {
      setSId(SSID);
    }
  }, [setSId]);

  const [chartData, setChartData] = useState({
    series: [
      {
      name: 'Purchased Amount',
      data: [0]
      }, 
  ],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
     
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      colors: ['#fd7e14'], 
    },  
  });
  useEffect(() => {
    fetch(`/api/getChartDatafd`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sId: sId,
        }),
    })
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset;
        // console.log('getChartDatafd', dataArray);
        if (Array.isArray(dataArray) && dataArray.length > 0) {
            const newSeries = dataArray.map(item => ({
                data: Object.values(item).slice(1) 
            }));

            setChartData(prevState => ({
                ...prevState,
                series: newSeries,
                options: {
                    ...prevState.options,
                    xaxis: {
                        categories: Object.keys(dataArray[0]).slice(1) 
                    }
                }
            }));
        } else {
            console.error('Invalid data format from the API');
        }
    })
    .catch(error => {
        // Handle any fetch errors
    });
  }, [sId]);

  // const [lineChartData, setLineChartData] = useState({
  //   series: [{
  //     name: currentMonthName,
  //     data: [0]
  //   }],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: '55%',
  //         endingShape: 'rounded'
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent']
  //     },
  //     xaxis: {
  //       categories: getDaysArray(new Date().getFullYear(), new Date().getMonth() + 1), 
  //       labels: {
  //           rotate: -90,
  //           formatter: function (val, timestamp) {
  //               const date = new Date(timestamp);
  //               return date.getDate(); // Display only the day of the month
  //           }
  //       }
  //   },
  //     yaxis: {
  //       title: {
  //         text: '# (Purchased Amount)'
  //       }
  //     },
  //     fill: {
  //       opacity: 1
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "# " + val 
  //         },
  //       },
  //     },
  //   },
  // });

  // function getDaysArray(year, month) {
  //   let startDate = new Date(Date.UTC(year, month, 1));
  //   let endDate;
  //   if (month === 1 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
  //       endDate = new Date(Date.UTC(year, month, 29));
  //   } else {
  //       endDate = new Date(Date.UTC(year, month + 1, 0));
  //   }

  //   let dates = [];
  //   while (startDate <= endDate) {
  //       dates.push(new Date(startDate));
  //       startDate.setUTCDate(startDate.getUTCDate() + 1);
  //   }
  //   return dates;
  // }
  // useEffect(() => {
  //   fetch(`/api/getlineChartDatafd`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       sId: sId,
  //     }),
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //       const dataArray = data.recordset;
  //       console.log('getlineChartDatafd', dataArray);
  //       if (Array.isArray(dataArray) && dataArray.length > 0) {
  //           // Map server data to the format expected by the chart
  //           const newSeries = dataArray.map(item => ({
  //               // data: Object.values(item).slice(1) // Assuming the values start from the second element
  //               data: [{ x: item.PDate.split('T')[0], y: item.Amount }]

  //           }))
  //           setLineChartData(prevState => ({
  //               ...prevState,
  //               series: newSeries,
  //           }));
  //       } else {
  //           console.error('Invalid data format from the API');
  //       }
  //   })
  //   .catch(error => {
  //       // Handle any fetch errors
  //   });
  // }, [sId]); 

  useEffect(() => {
    fetch(`/api/gettotalColl`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sId: sId,
      }),
    })
      .then(response => response.json())
      .then(data => {
        const dataArray = data.recordset;
        if (Array.isArray(dataArray) && dataArray.length > 0) {
          let totalCash = 0;
          let totalCard = 0;
          
          dataArray.forEach(item => {
            if (item.PurchaseType === 'Cash') {
              totalCash += item.Amount;
            } else if (item.PurchaseType === 'Card') {
              totalCard += item.Amount;
            }
          });
          setTotalCashColl(totalCash);
          setTotalCardColl(totalCard);
        } else {
          console.error('Invalid data format from the API');
        }
      })
      .catch(error => {
        // Handle any fetch errors
      });
  }, [sId]);

  useEffect(() => {
    fetch(`/api/getlineChartDatafd`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sId: sId,
      }),
    })
      .then(response => response.json())
      .then(data => {
        const dataArray = data.recordset;
        if (Array.isArray(dataArray) && dataArray.length > 0) {
          // Access the total number from the first recordset and update the state
          setTableData(dataArray);
        } else {
          console.error('Invalid data format from the API');
        }
      })
      .catch(error => {
        // Handle any fetch errors
      });
  }, [sId]); 

  useEffect(() => {
    const currentRoute = location.pathname;
    const newBreadcrumbs = generateBreadcrumbs(currentRoute);
    setBreadcrumbs(newBreadcrumbs);
  }, [location]);

  const generateBreadcrumbs = (currentRoute) => {
    const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

    const routeParts = currentRoute.split('/').filter(Boolean);
    let urlSoFar = '';
    routeParts.forEach((part) => {
      urlSoFar += `/${part}`;
      breadcrumbsArray.push({ label: part, url: urlSoFar });
    });

    return breadcrumbsArray;
  };

  return (
    <>     
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
      <div className='row mobileRow mx-2'>
        <div className="col-md-6">
        <div className="widget-flat card scrollOnXaxis pb-5 pt-3">
          <div className="card-body">
            <div className="float-end">
              <i className="card-icon shadow-primary"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16"><path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/></svg></i></div>
            <h5 className="fw-normal mt-0 text-muted" title="Growth">Purchased By Cash</h5>
            <h3 className="mt-3 mb-3">₹{totalCashColl || '0'}</h3>
          </div>
        </div>
        <div className="widget-flat card scrollOnXaxis mt-4 pb-5 pt-3">
          <div className="card-body">
            <div className="float-end">
              <i className="card-icon shadow-primary bg-blue"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-collection" viewBox="0 0 16 16"><path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5z"/></svg></i></div>
            <h5 className="fw-normal mt-0 text-muted" title="Growth">Purchased By Card</h5>
            <h3 className="mt-3 mb-3">₹{totalCardColl || '0'}</h3>
          </div>
        </div>
        </div>
        <div className='col-md-6 margin-top'>
          <div className="chart shadow-sm p-2 mb-3 bg-white rounded scrollOnXaxis">
            <h6>Monthly Purchased</h6>
            <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
          </div>
        </div>   
      </div>
      <div className='row mx-2'>
        <div className='col-md-12'>
          <div className="chart shadow-sm p-2 mb-3 bg-white rounded scrollOnXaxis">
            <h6>Daily Purchased</h6>
            {/* <ReactApexChart options={lineChartData.options} series={lineChartData.series} type="bar" height={350} /> */}
            <div className='d-felx scrollable-container'>
              <div className='shadow mb-5 rounded ' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <table className='table  table-nowrap mb-0 mt-3'>
                  <thead className='table-primary'>
                    <tr>
                      <th>#</th>
                      <th>Purchase Date</th>
                      <th>Purchase Type</th>
                      <th>Purchase Amount</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                  {tableData.map((item, index) => (
                    <tr key={index} className='text-center'>
                      <td>{serialNumber + index}</td>
                      <td className='text-left'><span className='badge badge-purple-lighten'>{item.PDate.slice(0, 10)}</span></td>
                      <td className='text-left'><span className='badge badge-orange-lighten'>{item.PurchaseType}</span></td>
                      <td className='text-left'><span className='badge badge-green-lighten'>{item.Amount}</span></td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
       
       
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
