import React, { useEffect, useState } from 'react';
import brand from '../Assets/BrandName&LogoHeader.png'

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const [serialNumber] = useState(1);
  const { pDate, purchaseType, loginName, sId } = props;
  const [invoiceData, setInvoiceData] = useState({});
  const [Data, setData] = useState([]);

  useEffect(() => {
    fetchData(pDate, purchaseType, loginName);
  }, [pDate, purchaseType, loginName]);
  
  const fetchData = async () => {
      try {
        const response = await fetch(`/api/getInvoiceData`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pDate, purchaseType, loginName, sId, }),
        });
  
        if (response.ok) {
          response
            .json()
            .then(formDataArray => {
              const formData = formDataArray[0];
              // console.log("formData", formData);
              setInvoiceData(formData);
            })
            .catch(error => {
              console.error('Error fetching form data from the API:', error);
            });
  
          // Fetch additional data
          fetch(`/api/getInvoiceData`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pDate, purchaseType, loginName, sId, }),
          })
            .then(response => response.json())
            .then(DataArray => {
              console.log(DataArray);
              setData(DataArray);
            })
            .catch(error => {
              console.error('Error fetching additional data from the API:', error);
            });
        } else {
          console.error('Error fetching form data');
        }
      } catch (error) {
        console.error('Error fetching form data:', error);
      }
  };

  const totalInvoiceAmount = Data.reduce((accumulator, currentItem) => {
    return accumulator + parseFloat(currentItem.InvAmount);
  }, 0);
  

  return (
    <>
    <div ref={ref} style={{margin:'20px 20px'}} className="component-to-print">
        <div className='shadow-sm py-2 px-3 mb-5 bg-white rounded border'>
            <div className='row border border-secondary'>
                <div className='col-md-12'>
                    <img src={brand} alt='Brand Name' className='Invoiceresponsive'  />
                </div>
                {/* <div className={window.innerWidth <= 767 ? 'col-md-6 invoice-md-6 mt-3' : 'col-md-6 invoice-md-6 mt-4'}>
                    <strong className='d-flex justify-content-end text-break fs-11'>Company MORD Al-Arabia For Trading LTD</strong>
                    <strong className='d-flex justify-content-end fs-12'>Riyadh</strong>
                    <strong className='d-flex justify-content-end fs-12'>Saudi Arabia</strong>
                </div> */}
                <hr className='my-1' style={{ height: '2px', backgroundColor: 'black', border: 'none' }} />
                <div className='col-md-12'>
                    <h3 className='d-flex justify-content-center fs-18'>Daily Purchases</h3>
                </div>
                <div className='col-md-6 invoice-md-6 px-0'>
                    <span className='px-3 d-flex justify-content-start text-danger fw-bold border-secondary border fs-15'>{invoiceData.PurchaseType || ''}</span>
                </div>
                <div className='col-md-6 invoice-md-6 px-0'>
                    <strong className='px-3 d-flex justify-content-end border-secondary border fs-15'>Date: {(invoiceData.PDate && invoiceData.PDate.slice(0, 10)) || ''}</strong>
                </div>
                {invoiceData.PurchaseType === 'Card' && (
                  <div className='col-md-6 invoice-md-6 my-3'>
                    <div>   
                      <span className='d-flex justify-content-start fs-15'>Card Name: &nbsp;<strong>{invoiceData.CardName || ''}</strong></span>
                      <span className='d-flex justify-content-start fs-15'>Card No: &nbsp;<strong>{invoiceData.CardNo || ''}</strong></span> 
                    </div>              
                  </div>
                )}
                <div className={`my-3 ${invoiceData.PurchaseType === 'Card' ? 'col-md-6 invoice-md-6' : 'col-md-12'}`}>
                    <h4 className='d-flex justify-content-end fs-12'>The Delegate: {invoiceData.EmployeeName || ''}</h4>
                </div>
                <div className='px-0'>
                <table className='table table-nowrap mb-0'>
                  <thead className='table-primary'>
                    <tr>
                      <th className='fs-12'>Notes</th>
                      <th className='fs-12'>Amount</th>
                      <th className='fs-12'>Description</th>
                      <th className='fs-12'>Dates</th>
                      <th className='fs-12'>{window.innerWidth <= 767 ? 'Inv No' : 'Invoice No'}</th>
                      <th className='fs-12'>#</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                  {Data.map((item, index) => (
                      <tr key={index} className='text-center'>
                        <td className='Invoice-text-left fs-12'>{item.Remarks}</td>
                        <td className='Invoice-text-left fs-12'>{item.InvAmount}</td>
                        <td className='Invoice-text-left fs-12'>{item.ShopName}</td>
                        <td className='Invoice-text-left fs-12'>{item.InvoiceDate.slice(0, 10)}</td>
                        <td className='Invoice-text-left fs-12'>{item.InvoiceNo}</td>
                        <td className='Invoice-text-left fs-12'>{serialNumber + index}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className='table-info'>
                      <td></td>
                      <td className='Invoice-text-left fs-12'>{totalInvoiceAmount}</td>
                      <td className='fs-12'>Total Amount</td> {/* Empty cells for alignment */}
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                </tfoot>
                </table>
                </div>
                <div className='d-flex justify-content-end'>
                <h5 className='border-secondary border-top mt-5 fs-15'>Signature</h5>
                </div>               
            </div>
        </div>   
    </div>
    </>
  );
});

