import React, { useRef, useEffect, useState } from 'react'
import {  Link, useLocation } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './Invoice';
import { useSession } from './SessionContext';

export default function AccountViewPurchase() {
    const registrationFormRef = useRef(null); 
    const [data, setData] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [invoiceData, setInvoiceData] = useState({});
    const [searchDate, setSearchDate] = useState('');
    const [pType, setPType] = useState('');
    const pTypeOption = ['Cash', 'Card']
    const [lName, setLName] = useState('');
    const [lnameId, setLNameId] = useState([]);
    const [lnameOption, setLNameOption] = useState([]);
    const [pDate, setPDate] = useState('');
    const [purchaseType, setPurchaseType] = useState('');
    const [loginName, setLoginName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isPrintEnabled, setIsPrintEnabled] = useState(false); 
    const [selectedItem, setSelectedItem] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/AccountDashboard' }]);
    const location = useLocation();
    const { sId, setSId} = useSession();

    useEffect(() => {
      var SSID = document.cookie.replace(/(?:(?:^|.*;\s*)sId\s*=\s*([^;]*).*$)|^.*$/, "$1");

      if (SSID) {
        setSId(SSID);
      }
    }, [setSId]);
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = `${now.getMonth() + 1}`.padStart(2, '0');
      const day = `${now.getDate()}`.padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    useEffect(() => {
      const currentDate = getCurrentDate();
      setSearchDate(currentDate);
    }, []);

    const handleDateChange = (e) => {
      setSearchDate(e.target.value);
    }
    const handlePtypeChange = (e) => {
      setPType( e.target.value)
    };
    const handleLNameChange = (e) => {
      const selectedValue = e.target.value
      setLName(selectedValue)
        const selectedId = lnameOption.find(option => option.name === selectedValue);
      if(selectedId){
        // console.log('Selected selectedClasses ID:', selectedClasses.id);
        setLNameId(selectedId.id)
      }
    };

    const handleReset = () => {
        setPType('');
        setLName('');
        setIsPrintEnabled(false);
    };

    useEffect(() => {
        fetchLoginName();
    }, [] );

    const fetchLoginName = async () => {
        try {
          const response = await fetch(`/api/getLoginName`); 
          if (response.ok) {
            const data = await response.json();
          //   console.log(data)
            const filteredOptions = data.recordset.filter(option => option.EmployeeName !== null).map(option=> ({
              name: option.EmployeeName,
              id: option.LoginID,
            }));
            setLNameOption(filteredOptions);
          } else {
            console.error('Error fetching options');
          }
        } catch (error) {
            console.error('Error fetching options:', error);
          }
    };

    useEffect(() => {
        const filterCriteria = {
          searchDate,
          pType,
          lnameId,
          sId,
        };
    
        const filterDatas = async () => {
          try {
            const response = await fetch(`/api/getFilteredQryDetails`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(filterCriteria),
            });
    
            if (response.ok) {
              const filteredData = await response.json();
              setData(filteredData);
            } else {
              console.error('Error fetching filtered data');
            }
          } catch (error) {
            console.error('Error fetching filtered data:', error);
          }
        };
    
        filterDatas(); 
    }, [searchDate, pType, lnameId, sId]);

     useEffect(() => {
      const currentRoute = location.pathname;
      const newBreadcrumbs = generateBreadcrumbs(currentRoute);
      setBreadcrumbs(newBreadcrumbs);
    }, [location]);
  
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/AccountDashboard' }];
  
      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part) => {
        urlSoFar += `/${part}`;
        breadcrumbsArray.push({ label: part, url: urlSoFar });
      });
  
      return breadcrumbsArray;
    };

    const getStatusClass = (value) => {
      switch (value) {
        case 'Cash':
          return 'badge-purple-lighten';
        case 'Card':
          return 'badge-yellow-lighten';
        default:
          return 'badge-info-lighten';
      }
    };

    const generatePDF = async (PDate, PurchaseType, LoginName ) => {
        try {  
          const response = await fetch(`/api/PrintInvoice/${PDate}/${PurchaseType}/${LoginName}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/pdf',
              },
          });
    
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
    
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Invoice.pdf';
                a.click();
    
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error fetching data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const handleClick = (PDate, PurchaseType, LoginName, index) => {
      setPDate(PDate);
      setPurchaseType(PurchaseType);
      setLoginName(LoginName);
      setSelectedItem(index)
      setIsPrintEnabled(true);
    };

    useEffect(() => {
      fetchData(pDate, purchaseType, loginName);
    }, [pDate, purchaseType, loginName]);
    
    const fetchData = async () => {
      if(pDate, purchaseType, loginName){
        try {
          const response = await fetch(`/api/getInvoiceData`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pDate, purchaseType, loginName, sId, }),
          });
    
          if (response.ok) {
            const data = await response.json();
            if (Array.isArray(data) && data.length > 0) {
              const firstDataObject = data[0];
              setInvoiceData(firstDataObject); 
            } else {
              console.error('No data found in the response');
            }
          } else {
            console.error('Error fetching form data');
          }
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      }
    };
  
    const handleImgViewGrid = async (purchaseType, loginName) => {
      setShowModal(true);
      filterData(purchaseType, loginName, sId); 
    };
    
    const filterData = async (purchaseType, loginName) => {
      try {
        const response = await fetch(`/api/getImageGriddetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ purchaseType, loginName, sId }), // Pass purchaseType and loginName as an object
        });
    
        if (response.ok) {
          const filteredData = await response.json();
          setImageData(filteredData);
        } else {
          console.error('Error fetching filtered data');
        }
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    };

    const handleRejectInvoice = async (PID) => {
      // console.log(PID)
      try {
        const response = await fetch(`/api/RejectInvoice`, {
          method: 'PUT', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({PID}),
        });
    
        if (response.ok) {
          setData((prevData) => prevData.filter(item => item.PID !== PID));
        } else {
          console.error('Error submitting');
        }
      } catch (error) {
        console.error('Error submitting:', error);
      }
    };

    const openImageInNewTab = (imageUrl) => {
      window.open(imageUrl, '_blank').focus();
    };
   
  return (
    <>
{showModal && (
  <div className="modal-backdrop show"></div>
)}
<div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content shadow-lg mb-5 bg-white rounded">
        <div className="modal-header">
          <h5 className="modal-title">View Purchase Invoice Picture</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" onClick={() => setShowModal(false)}><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/></svg>
        </div>
        <div className="modal-body">
          <div className='d-flex justify-content-start border-bottom'>
            <h5>{imageData.EmployeeName}</h5>
          </div>
          {imageData.map((item, index) => (
            <div key={index} className='row' >
              <div className='col-md-6 custom-md-6 '>
              <div className='py-2 border-bottom text-center'>
                <span onClick={() => openImageInNewTab(`/api/Uploads/${item.InvImage}`)}>
                  <img src={`/api/Uploads/${item.InvImage}`} alt="img" width="100px" height="100px" />
                </span>
              </div>
              </div>
              <div className='col-md-6 py-2 custom-md-6 '>
                <strong className='ml-7'>{item.ShopName}</strong>
                <p className='fs-12 ml-7 mb-0'>{item.PurchaseType}</p>
                <p className='fs-12 ml-7 mb-0'>{item.InvAmount} SAR</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
</div>
<div className='my-2'>
  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
  <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
   <form>
        <div className='row'>
            <div className='col-md-2'>
                <div>
                    <input  className='form-input' type="Date" value={searchDate}  onChange={handleDateChange} required />
                </div>
            </div>
            <div className='col-md-4'>
                <div className='margin-top'>
                    <select className="form-control" id="dropdown" value={pType} onChange={handlePtypeChange}>
                    <option value="">Select Purchase Type</option>
                    {pTypeOption.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='margin-top'>
                    <select className="form-control" id="dropdown" value={lName} onChange={handleLNameChange}>
                    <option value="">Select Purchaser Name</option>
                    {lnameOption.map((option, index) => (
                        <option key={index} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            <div className='col-md-2'>
              <div className='margin-top'>
                <button className='btn btn-warning btn-width' onClick={handleReset}>Reset</button>
              </div>
            </div>
        </div>
    </form>
    </div>
    <div className='d-felx scrollable-container mx-2'>
        <div className='shadow mb-5 rounded viewPurchasescrollOnXaxis' style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <table className='table  table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th className='text-center'>Date</th>
              <th className='text-center'>Type</th>
              <th className='text-center'>Purchaser Name</th>
              <th className='text-center'>Amount</th>
              <th className='text-center'>Invoice</th>
              <th className='text-center'>Image</th>
              <th className='text-center'>Reject</th>
            </tr>
          </thead>
          <tbody className='text-center'>
          {data.map((item, index) => (
              <tr key={index} className='text-center'>
                <td>{item.PDate.slice(0, 10)}</td>
                <td><span className={`badge ${getStatusClass(item.PurchaseType)}`}>{item.PurchaseType}</span></td>
                <td>{item.EmployeeName}</td>
                <td>{item.Amount}</td>
                <td><p  className='badge badge-info-lighten' onClick={() => generatePDF(item.PDate, item.PurchaseType, item.LoginName)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16"><path d="M5.523 12.424q.21-.124.459-.238a8 8 0 0 1-.45.606c-.28.337-.498.516-.635.572l-.035.012a.3.3 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647q-.178.037-.356.078a21 21 0 0 0 .5-1.05 12 12 0 0 0 .51.858q-.326.048-.654.114m2.525.939a4 4 0 0 1-.435-.41q.344.007.612.054c.317.057.466.147.518.209a.1.1 0 0 1 .026.064.44.44 0 0 1-.06.2.3.3 0 0 1-.094.124.1.1 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a5 5 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.5.5 0 0 1 .145-.04c.013.03.028.092.032.198q.008.183-.038.465z"/><path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.7 11.7 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.86.86 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.84.84 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.8 5.8 0 0 0-1.335-.05 11 11 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.24 1.24 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a20 20 0 0 1-1.062 2.227 7.7 7.7 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103"/></svg></p>
                  {isPrintEnabled && selectedItem === index ? (
                     invoiceData && (
                      <ReactToPrint 
                      trigger={() =><span className='badge badge-info-lighten mx-2' data-toggle="modal" data-target="#exampleModalCenter"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer-fill" viewBox="0 0 16 16">
                      <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1"/>
                      <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/></svg></span>}
                      content={() => registrationFormRef.current}
                      onAfterPrint={handleReset}
                      closeAfterPrint={handleReset}
                      />
                      )
                  ): (
                    <p className='badge badge-warning-lighten mx-2' onClick={() => handleClick(item.PDate, item.PurchaseType, item.LoginName, index)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"><path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/> </svg></p>
                  )}
                </td>
                <td><p className='badge badge-success-lighten mx-2' onClick={() => handleImgViewGrid(item.PurchaseType, item.LoginName)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-image-fill" viewBox="0 0 16 16">  <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707v5.586l-2.73-2.73a1 1 0 0 0-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 0 0-1.222.15L2 12.292V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zm-1.498 4a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/><path d="M10.564 8.27 14 11.708V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z"/></svg></p></td>
                <td><p className='badge badge-danger-lighten mx-2' onClick={() => handleRejectInvoice(item.PID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ban-fill" viewBox="0 0 16 16">  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M2.71 12.584q.328.378.706.707l9.875-9.875a7 7 0 0 0-.707-.707l-9.875 9.875Z"/></svg></p></td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
      </div>
      <div style={{ display: 'none' }}>
  {invoiceData && <ComponentToPrint ref={registrationFormRef} pDate={pDate} sId={sId} purchaseType={purchaseType} loginName={loginName} />}
      </div>
    </>
  )
}

