import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import { useNavigate, Link, useLocation } from "react-router-dom";

export default function RejectMster() {
    const {sId, setSId, PurchaseLoginId, setPurchaseLoginId,} = useSession();
    const [data, setData] = useState([]);
    const [pType, setPType] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/PurchaseDashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      var sId = document.cookie.replace(/(?:(?:^|.*;\s*)sId\s*=\s*([^;]*).*$)|^.*$/, "$1");
      var loginId = document.cookie.replace(/(?:(?:^|.*;\s*)PurchaseLoginId\s*=\s*([^;]*).*$)|^.*$/, "$1");

      if (sId && loginId) {
        setSId(sId);
        setPurchaseLoginId(loginId);
      }
    }, [setSId, setPurchaseLoginId]);

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const pTypeParam = searchParams.get('pType');
      if (pTypeParam) {
        setPType(pTypeParam);
      }
    }, [location.search]);

    useEffect(() => {
      fetchPurchase(pType);
    }, [pType]);

    const fetchPurchase = async () => {
      try {
          const response = await fetch(`/api/getrejected/${pType}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ sId, PurchaseLoginId })
          });
  
          const data = await response.json();
  
          const dataArray = data.recordset;
          if (Array.isArray(dataArray)) {
              setData(dataArray);
          } else {
              console.error('Invalid data format from the API:', data);
          }
      } catch (error) {
          console.error('Error fetching options:', error);
      }
    };

    const handleEditClick = (PID) => {
      navigate(`/PurchaseMaster/${PID}?pType=${pType}`)
    };

    useEffect(() => {
      const currentRoute = location.pathname;
      const newBreadcrumbs = generateBreadcrumbs(currentRoute);
      setBreadcrumbs(newBreadcrumbs);
    }, [location]);
  
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/PurchaseDashboard' }];
  
      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part) => {
        urlSoFar += `/${part}`;
        breadcrumbsArray.push({ label: part, url: urlSoFar });
      });
  
      return breadcrumbsArray;
    };
     
  return (
    <>
<div className='my-2'>
  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
  <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
  <h5 className='text-center'>Update Daily Purchase {pType}</h5>
    </div>
    <div className='d-felx mx-2'>
      <div className='shadow mb-5 rounded PurchaseMasterscrollOnXaxis'>
        <table className='table table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Edit</th>
              <th>Notes</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Date</th>
              <th>InvoiceNo</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.PID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg></p></td>
                <td>{item.Remarks}</td>
                <td>{item.InvAmount}</td>
                <td>{item.ShopName}</td>
                <td>{item.InvoiceDate.slice(0, 10)}</td>
                <td>{item.InvoiceNo}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
</div>
    </>
  )
}