import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from './component/SessionContext';
import Navbar from './component/Navbar';
import SideNavBar from './component/SideNavBar';
import MyLogin from './component/MyLogin';
import Dashboard from './component/Dashboard';
import CityMaster from './component/CityMaster';
import ShopMaster from './component/ShopMaster';
import BranchMaster from './component/BranchMaster';
import PurchaseMaster from './component/PurchaseMaster';
import PurchaseFinal from './component/PurchaseFinal';
import RejectMster from './component/RejectMster';
import PurchaseSidebar from './component/PurchaseSidebar';
import PurchaseNavbar from './component/PurchaseNavbar';
import PurchaseDashboard from './component/PurchaseDashboard';
import AccountSidebar from './component/AccountSidebar';
import AccountNavbar from './component/AccountNavbar';
import AccountDashboard from './component/AccountDashboard';
import AccountViewPurchase from './component/AccountViewPurchase';
import LoginMaster from './component/LoginMaster';
import './App.css';
import './CSS/login.css';
import './CSS/component.css';
import './CSS/mobile.css';
import './CSS/Invoice.css';

function App() {
const loggedIn = localStorage.getItem("isLoggedIn");
const purcLoggedIn = localStorage.getItem("isPurchaseLoggedIn");
const accLoggedIn = localStorage.getItem("isAccountLoggedIn");
// console.log(loggedIn, 'LogIn', purcLoggedIn, 'purcLoggedIn', accLoggedIn, 'accLoggedIn'); 
  
  return (
    <Router>
      <SessionProvider>
        <Routes>
          <Route path="/" element={
            <>
              <MyLogin />
            </>
          } />
          <Route path="/Dashboard" element={
            <>
              { loggedIn ? (
                <div className='app-container'>
                <SideNavBar />
                <div className='content'>
                  <Navbar />
                  <Dashboard />
                </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/PurchaseDashboard" element={
            <>
              { purcLoggedIn ? (
                <div className='app-container'>
                  <PurchaseSidebar />
                <div className='content'>
                  <PurchaseNavbar />
                  <PurchaseDashboard />
                </div>
              </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/AccountDashboard" element={
            <>
             { accLoggedIn ? (
                 <div className='app-container'>
                 <AccountSidebar />
                 <div className='content'>
                   <AccountNavbar />
                   <AccountDashboard />
                 </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/AccountViewPurchase" element={
            <>
             { accLoggedIn ? (
                 <div className='app-container'>
                 <AccountSidebar />
                 <div className='content'>
                   <AccountNavbar />
                   <AccountViewPurchase />
                 </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/LoginMaster/:LoginID?" element={
            <>
             { accLoggedIn ? (
                 <div className='app-container'>
                 <AccountSidebar />
                 <div className='content'>
                   <AccountNavbar />
                   <LoginMaster />
                 </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/CityMaster/:CID?" element={
            <>
             { purcLoggedIn ? (
                  <div className='app-container'>
                  <PurchaseSidebar />
                  <div className='content'>
                    <PurchaseNavbar />
                    <CityMaster />
                  </div>
                  </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/ShopMaster/:SID?" element={
            <>
             { purcLoggedIn ? (
                 <div className='app-container'>
                 <PurchaseSidebar />
                 <div className='content'>
                   <PurchaseNavbar />
                   <ShopMaster />
                 </div>
                 </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/BranchMaster/:BID?" element={
            <>
             { purcLoggedIn ? (
                 <div className='app-container'>
                 <PurchaseSidebar />
                 <div className='content'>
                   <PurchaseNavbar />
                   <BranchMaster />
                 </div>
                 </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/PurchaseMaster/:PID?" element={
            <>
             { purcLoggedIn ? (
                <div className='app-container'>
                <PurchaseSidebar />
                <div className='content'>
                  <PurchaseNavbar />
                  <PurchaseMaster />
                </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/PurchaseFinal" element={
            <>
             { purcLoggedIn ? (
                <div className='app-container'>
                <PurchaseSidebar />
                <div className='content'>
                  <PurchaseNavbar />
                  <PurchaseFinal />
                </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
          <Route path="/RejectMster" element={
            <>
             { purcLoggedIn ? (
                <div className='app-container'>
                <PurchaseSidebar />
                <div className='content'>
                  <PurchaseNavbar />
                  <RejectMster />
                </div>
                </div>
              ) : (
                <MyLogin />
              )}
            </>
          } />
        </Routes>
      </SessionProvider>
    </Router>
  );
}

export default App;
