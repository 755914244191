import React, { useEffect, useState, useRef } from 'react'
import { useSession } from './SessionContext';
import imageCompression from 'browser-image-compression';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

export default function PurchaseMaster() {
    const fileInputRef = useRef(null);
    const {sId, setSId, PurchaseLoginId, setPurchaseLoginId,} = useSession();
    const { PID } = useParams();
    const [data, setData] = useState([]);
    const [date, setDate] = useState('');
    const [city, setCity] = useState('');
    const [cityId, setCityId] = useState('');
    const [cityName, setCityName] = useState('');
    const [cityNameId, setCityNameId] = useState('');
    const [cityOption, setCityOption] = useState([]);
    const [shop, setShop] = useState('');
    const [shopId, setShopId] = useState([]);
    const [shopOption, setShopOption] = useState([]);
    const [branch, setBranch] = useState('');
    const [branchId, setBranchId] = useState([]);
    const [branchOption, setBranchOption] = useState([]);
    const [invNum, setInvNum] = useState('');
    const [invDate, setInvDate] = useState('');
    const [invAmnt, setInvAmnt] = useState('');
    const [pType, setPType] = useState('');
    const [invImg, setInvImg] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [DeleteId, setDeleteId] = useState(''); 
    const [statusId, setStatusId] = useState(''); 
    const [isDeleted, setIsDeleted] = useState(false); 
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/PurchaseDashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      var sId = document.cookie.replace(/(?:(?:^|.*;\s*)sId\s*=\s*([^;]*).*$)|^.*$/, "$1");
      var loginId = document.cookie.replace(/(?:(?:^|.*;\s*)PurchaseLoginId\s*=\s*([^;]*).*$)|^.*$/, "$1");

      if (sId && loginId) {
        setSId(sId);
        setPurchaseLoginId(loginId);
      }
    }, [setSId, setPurchaseLoginId]);

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const pTypeParam = searchParams.get('pType');
      if (pTypeParam) {
        setPType(pTypeParam);
      }
    }, [location.search]);

    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = `${now.getMonth() + 1}`.padStart(2, '0');
      const day = `${now.getDate()}`.padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    useEffect(() => {
      const currentDate = getCurrentDate();
      setDate(currentDate);
      setInvDate(currentDate);
    }, []);

    const handleCityChange = (e) => {
      const selectedValue = e.target.value;
      setCity(selectedValue);
      setCityId(selectedValue); 
    };
  
    useEffect(() => {
        if (cityOption.length > 0) {
            setCity(cityOption[0].name);
            setCityId(cityOption[0].id);
        }
    }, [cityOption]);

    const handleCityNameChange = (e) => {
        const selectedValue = e.target.value
        setCityName(selectedValue)
        const selectedShop = cityOption.find(option => option.name === selectedValue);
        if(selectedShop){
          // console.log('Selected selectedClasses ID:', selectedClasses.id);
          setCityNameId(selectedShop.id)
        }
    };

    const handleShopChange = (e) => {
        const selectedValue = e.target.value
        setShop(selectedValue)
        const selectedShop = shopOption.find(option => option.name === selectedValue);
        if(selectedShop){
          // console.log('Selected selectedClasses ID:', selectedClasses.id);
          setShopId(selectedShop.id)
        }
    };

    const handleBranchChange = (e) => {
      const selectedValue = e.target.value
      setBranch(selectedValue)
      const selectedBranch = branchOption.find(option => option.name === selectedValue);
      if(selectedBranch){
        // console.log('Selected selectedClasses ID:', selectedClasses.id);
        setBranchId(selectedBranch.id)
      }
    };

    const handleInvNumChange = (e) => {
        setInvNum(e.target.value)
    };

    const handleInvDateChange = (e) => {
        setInvDate(e.target.value)
    };

    const handleInvAmntChange = (e) => {
        setInvAmnt(e.target.value)
    };

    const handleInvImgChange = async (e) => {
      const selectedImage = e.target.files[0];
      const options = {
        maxSizeMB: 0.04, 
        maxWidthOrHeight: 800,
        useWebWorker: true 
      };
  
      try {
        const compressedFile = await imageCompression(selectedImage, options);
        setInvImg(compressedFile);
      } catch (error) {
        console.error('Image compression error:', error);
      }   
    };

    const handleRemarksChange = (e) => {
        setRemarks(e.target.value)
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    const handleReset = () => {
      setCity('');
      setCityId([]);
      setShop('');
      setShopId([]);
      setBranch('');
      setBranchId([]);
      setInvNum('');
      setInvAmnt('');
      setRemarks('');
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the input field value
      }
      navigate(`/PurchaseMaster?pType=${pType}`)
    };

    useEffect(() => {
      fetchPurchase(pType);
      fetchShop();
      fetchCity();
      if (shopId !== null) {
        fetchBranch();
      }
    }, [pType, shopId]);

    const fetchPurchase = async () => {
      try {
          const response = await fetch(`/api/getPurchased/${pType}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ PurchaseLoginId })
          });
  
          const data = await response.json();
  
          const dataArray = data.recordset;
          if (Array.isArray(dataArray)) {
              const allPIDs = dataArray.map((item) => item.PID);
              setStatusId(allPIDs);
              setData(dataArray);
          } else {
              console.error('Invalid data format from the API:', data);
          }
      } catch (error) {
          console.error('Error fetching options:', error);
      }
    };
      
    const fetchCity = async () => {
        try {
          const response = await fetch(`/api/getCity`); 
          if (response.ok) {
            const data = await response.json();
          //   console.log(data)
            const filteredOptions = data.recordset.filter(option => option.CityName !== null).map(option=> ({
              name: option.CityName,
              id: option.CID, 
            }));
            setCityOption(filteredOptions);
            // console.log(filteredOptions) 
          } else {
            console.error('Error fetching options');
          }
        } catch (error) {
            console.error('Error fetching options:', error);
          }
    };

    const fetchShop = async () => {
        try {
          const response = await fetch(`/api/getShop`); 
          if (response.ok) {
            const data = await response.json();
          //   console.log(data)
            const filteredOptions = data.recordset.filter(option => option.ShopName !== null).map(option=> ({
                id: option.SID, 
                name: option.ShopName,
            }));
            setShopOption(filteredOptions);
            // console.log(filteredOptions) 
          } else {
            console.error('Error fetching options');
          }
        } catch (error) {
            console.error('Error fetching options:', error);
          }
    };

    const fetchBranch = async () => {
      try {
        const response = await fetch(`/api/getOnlyBranchName/${shopId}`); 
        if (response.ok) {
          const data = await response.json();
          // console.log(data)
          const filteredOptions = data.recordset.filter(option => option.BranchName !== null).map(option=> ({
              id: option.BID, 
              name: option.BranchName,
          }));
          setBranchOption(filteredOptions);
        } else {
          console.error('Error fetching options');
        }
      } catch (error) {
          console.error('Error fetching options:', error);
      }
    };

    useEffect(() => {
      if (PID) {
        fetch(`/api/getPurchase/${PID}`)
          .then((response) => response.json())
          .then((result) => {
            if (result && result.length > 0) {
              const clientdata = result[0];
              setDate(clientdata.PDate.slice(0, 10) || '');
              setCityName(clientdata.CityName || '');
              setCityNameId(clientdata.CID || '');
              setShop(clientdata.ShopName || '');
              setShopId(clientdata.SID || []);
              setBranch(clientdata.BranchName || '');
              setBranchId(clientdata.BID || []);
              setInvNum(clientdata.InvoiceNo || '');
              setInvDate(clientdata.InvoiceDate.slice(0, 10) || '');
              setInvAmnt(clientdata.InvAmount || '');
              setInvImg(clientdata.InvImage || null);
              setRemarks(clientdata.Remarks || '');
            } else {
              console.log('No data received');
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } else {
        setCity('');
        setCityId([]);
        setShop('');
        setShopId([]);
        setInvNum('');
        setInvAmnt('');
        setRemarks('');
      }
    }, [PID]);

    const handleSubmit = async (e) => {
      console.log('handlenoticeupload called');
      if (!cityId) {
        alert('Oops! The City Name Field Is Empty');
        return;
      } else if (!shopId) {
        alert('Oops! The Shop Name Field Is Empty');
        return;
      } 

      e.preventDefault();
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 1000); 

      const formData = new FormData();
      formData.append('date', date);
      formData.append('cityNameId', cityNameId);
      formData.append('cityId', cityId);
      formData.append('shopId', shopId);
      formData.append('branchId', branchId);
      formData.append('invNum', invNum);
      formData.append('invDate', invDate);
      formData.append('invAmnt', invAmnt);
      formData.append('pType', pType);
      formData.append('invImg', invImg);
      formData.append('remarks', remarks);
      formData.append('sId', sId);
      formData.append('PurchaseLoginId', PurchaseLoginId);
    
        if(PID){
          try {
            const response = await fetch(`/api/UpdatePurchase/${PID}`, {
              method: 'PUT', 
              body: formData,
          });
      
            if (response.ok) {
              setSuccessMessage('Purchase Updated successfully.');
              setCityName('');
              setCityNameId('');
              setShop('');
              setShopId([]);
              setBranch('');
              setBranchId([]);
              setInvNum('');
              setInvAmnt('');
              setRemarks('');    
              fetchPurchase(pType);
              navigate(`/PurchaseMaster?pType=${pType}`)
            } else {
              console.error('Error updating Shop');
            }
          } catch (error) {
            console.error('Error updating Shop:', error);
          }
        } else {
          try {
            const response = await fetch(`/api/CreatePurchase`, {
              method: 'POST',
              body: formData,
          });
    
            if (response.ok) {
              setSuccessMessage('Purchase Created successfully.');
              setCity('');
              setCityId([]);
              setShop('');
              setShopId([]);
              setBranch('');
              setBranchId([]);
              setInvNum('');
              setInvAmnt('');
              setRemarks('');
              fetchPurchase(pType);
            } else {
              console.error('Error creating Shop');
            }
          } catch (error) {
            console.error('Error creating Shop:', error);
          }
        } 
      setIsSubmitting(false);
    };

    const handleStatus = async (e) => {
      e.preventDefault();
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 1000); 
      
      try {
        const response = await fetch(`/api/UpdateStatus`, {
          method: 'PUT', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({statusId}),
        });
    
        if (response.ok) {
          setSuccessMessage('Records Submitted!');
          setStatusId(''); // Assuming statusId should be reset to an empty string after submission
          fetchPurchase(pType);
          navigate(`/PurchaseMaster`);
        } else {
          console.error('Error submitting');
        }
      } catch (error) {
        console.error('Error submitting:', error);
      }
    };

    const handleEditClick = (PID) => {
      navigate(`/PurchaseMaster/${PID}?pType=${pType}`)
    };

    const handleConfirmation = async (PID) => {
      setIsDeleted(true);
      setDeleteId(PID)
    };

    const handleCancel = async () => {
      setIsDeleted(false);
      setDeleteId('')
    };

    const handleDelete = async (PID) => {
      try {
        const response = await fetch(`/api/DeletePurchase/${PID}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          setData((prevData) => prevData.filter(item => item.PID !== PID));
          setSuccessMessage('Purchase Deleted successfully.');
          setShowModal(true);
          setTimeout(() => {
            removeSuccessMessage();
          }, 2000);
          setIsDeleted(false);
          DeleteId('');
        } else {
          console.error('Error deleting Purchase');
        }
      } catch (error) {
        console.error('Error deleting Purchase:', error);
      }
    };

    useEffect(() => {
      const currentRoute = location.pathname;
      const newBreadcrumbs = generateBreadcrumbs(currentRoute);
      setBreadcrumbs(newBreadcrumbs);
    }, [location]);
  
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/PurchaseDashboard' }];
  
      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part) => {
        urlSoFar += `/${part}`;
        breadcrumbsArray.push({ label: part, url: urlSoFar });
      });
  
      return breadcrumbsArray;
    };
     
  return (
    <>
{showModal && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Success Message</h5>
          </div>
          <div className="modal-body">
            {successMessage && <p className='text-success'>{successMessage}</p>}
          </div>
        </div>
      </div>
  </div>
<div className='my-2'>
  <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
            {index === breadcrumbs.length - 1 ? (
              breadcrumb.label
            ) : (
              <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ol>
  </nav>
  <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
  <h5 className='text-center'>{PID ? `Update Daily Purchase ${pType}` : `Create Daily Purchase ${pType}`}</h5>
    <form>
        <div className='row'>
            <div className='col-md-2 custom-md-6'>
                <div  className='margin-top'>
                    {PID ? (<select className="form-control" id="dropdown" value={cityName} onChange={handleCityNameChange}>
                    {cityOption.map((option, index) => (
                        <option key={index} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                    </select>    
                    ) : (
                      <select className="form-control" id="dropdown" value={city} onChange={handleCityChange}>
                    {cityOption.map((option, index) => (
                        <option key={index} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                    </select>    
                    )}          
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <select className="form-control" id="dropdown" value={shop} onChange={handleShopChange}>
                    <option value="">Select Shop</option>
                    {shopOption.map((option, index) => (
                        <option key={index} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                    </select>                
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <select className="form-control" id="dropdown" value={branch} onChange={handleBranchChange}>
                    <option value="">Select Branch</option>
                    {branchOption.map((option, index) => (
                        <option key={index} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                    </select>                
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input form-right' type="number" placeholder='Enter Invoice Number' value={invNum}  onChange={handleInvNumChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input' type="date" placeholder='Enter Date' value={invDate}  onChange={handleInvDateChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='margin-top'>
                    <input  className='form-input form-right' type="number" placeholder='Enter Invoice Amount' value={invAmnt}  onChange={handleInvAmntChange} required />
                </div>
            </div>
            <div className='col-md-4 custom-md-6'>
                <div className='mt-3'>
                  <input  className='form-input' type="file" accept="image/*" name="invImg" ref={fileInputRef} onChange={handleInvImgChange} />
                </div>
            </div>
            <div className='col-md-4 custom-md-6'>
                <div className='mt-3'>
                    <input  className='form-input' type="text" placeholder='Enter Your Remarks' value={remarks}  onChange={handleRemarksChange} required />
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='mt-3'>
                    <button  className='btn btn-info btn-width' onClick={handleSubmit}>{PID ? 'Update' : 'Save'}</button>
                </div>
            </div>
            <div className='col-md-2 custom-md-6'>
                <div className='mt-3'>
                    <button className='btn btn-warning btn-width' onClick={handleReset}>Reset</button>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div className='d-felx mx-2'>
      <div className='shadow mb-5 rounded PurchaseMasterscrollOnXaxis'>
        <table className='table table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Delete</th>
              <th>Edit</th>
              <th>Notes</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Date</th>
              <th>InvoiceNo</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td><p className='badge badge-danger-lighten' onClick={() => handleConfirmation(item.PID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/></svg></p></td>
                <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.PID)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg></p></td>
                <td>{item.Remarks}</td>
                <td>{item.InvAmount}</td>
                <td>{item.ShopName}</td>
                <td>{item.InvoiceDate.slice(0, 10)}</td>
                <td>{item.InvoiceNo}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>

    <div className='shadow-sm p-3 mb-3 bg-white rounded mx-2'>
        <div className='d-flex justify-content-center'>
            <div className='my-1'>
                <button className='btn btn-success px-5' onClick={handleStatus}>Final Submit</button>
            </div>
        </div>
    </div>
    {isDeleted && (
  <div className="modal-backdrop show"></div>
)}
  <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
          <div className="modal-header">
            <h5 className="modal-title">Delete <strong>Purchase?</strong></h5>
          </div>
          <div className="modal-body">
            <p>This Will Delete Purchase <span className='text-danger'>Permannently</span></p>
            <div className='d-flex flex-row-reverse '>
            <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
            <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
    </>
  )
}
