import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sId, setSId] = useState(null);
  const [sYear, setSyear] = useState(null);
  const [puchaseType, setPuchaseType] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [userLoginType, setUserLoginType] = useState(null);
  const [purchaserName, setPurchaserName] = useState(null);
  const [accountantName, setAccountantName] = useState(null);
  const [PurchaseLoginId, setPurchaseLoginId] = useState(null);
  const [accountLoginId, setAccountLoginId] = useState(null);

  return (
    <SessionContext.Provider value={{ sId, setSId, sYear, setSyear, puchaseType, setPuchaseType, accountType, setAccountType, userLoginType, setUserLoginType, purchaserName, setPurchaserName, accountantName, setAccountantName, PurchaseLoginId, setPurchaseLoginId, accountLoginId, setAccountLoginId }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};
